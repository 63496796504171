import { FC } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";


//import { Page404 } from "../common/pages/Page404";
import { HomeRoutes } from "./HomeRoutes";

export const Router: FC = () => {


    let url = new URL(window.location.href);

    //2023-08-31.DEBUG用
    console.log("[12] ----- URL = " + url);

    return (

        <Routes >
            {HomeRoutes.map((route, index) => {
                return (
                    <>
                        {console.log("[16]--------" + route.path)}
                        <Route
                            key={index}
                            path={route.path}
                            element={route.chidlen}
                        >
                        </Route>
                    </>
                );
            })
            }
            {/* <Route key="99" path="*" element={<Page404 />} /> */}
        </Routes>


    )

}