

export const Conf = {
    DEBUG_MODE: true,

    APP_NAME: "ＷＥＢシステム",

    /********** 本番に持っていく場合変更します。 **********/
    APP_SERVER_HTTPHOST: "http://localhost",          //開発
    //APP_SERVER_HTTPHOST: "http://10.0.0.66:20080",         //DEV66
    //APP_SERVER_HTTPHOST: "https://srp.skk.jp",        //本番    

    //クライアント側 2023-08-30.使用していない。
    //APP_SERVER_HTTPHOST_C: "http://localhost",   //開発
    //APP_SERVER_HTTPHOST_C: "http://localhost:3000",   //開発
    //APP_SERVER_HTTPHOST_C: "http://10.0.0.66:20080",       //DEV66
    //APP_SERVER_HTTPHOST_C: "https://srp.skk.jp",      //本番

    /** *********************************************** */

    //サーバ側
    APP_MAIN_DIRECTORY: "spsat",


    GOOGLE_MAP_URL: "https://www.google.co.jp/maps/place/",


    //クライアント側　2023-08-30.使用していない。
    //APP_MAIN_DIRECTORY_C: "/msc",

    /** 実行時のURLサブパス */
    URL_SUB: "/",


    URL_MENU: "/irm_main_menu",


    HELP_URL: "https://srp.skk.jp/stree/app/stree_main.php?FORM_NAME=htmsTreeMain&TRANS_NAME=htmsTreeMain_01_select&CUD_TYPE_CODE=11&VIEW_NO=0&dno=264441",

}


export const ConstStr = {
    LINE_DELIMIT: "#",
}

export const DialogTitle = {
    DAIALOG_INFO: 'メッセージ',
}

export const LocalStrageKey = {

    KAISYA_CODE: 'public.kaisya_code',

    SITEN_CODE: 'public.siten_code',
    COMBOKEY_SITENS: 'public.sitens',

    KESSAN_NENDO: 'public.kessan_nendo',
    COMBOKEY_KESSAN_NENDOS: 'public.kessan_nendos',

    TODOFUKEN_CODE: 'public.todofuken_code',
    COMBOKEY_TODOFUKEN_CODES: 'public.todofuken_codes',

    KOUZI_KUBUN_CODE: 'public.kouzi_kubun_code',
    COMBOKEY_KOUZI_KUBUN_CODES: 'public.kouzi_kubun_codes',

    KESSAN_PARTITION_CODE: 'public.kessan_partition_code',
    COMBOKEY_KESSAN_PARTITION_CODES: 'public.kessan_partition_codes',

    KESSAN_TATEMONO_YOUTO_CODE: 'public.tatemono_yoUto_code',
    COMBOKEY_TATEMONO_YOUTO_CODE: 'public.tatemono_yoUto_codes',

    /** MSカテゴリーコード */
    MS_CATEGORY_CODE: 'public.ms_category_code',                //選択コード
    COMBOKEY_MS_CATEGORY_CODES: 'public.ms_category_codes',     //ローカルストレージ用コード一覧

    /** MS項目区分コード */
    MS_KOUMOKU_PARTITION_CODE: 'public.ms_koumoku_partition_code',                //選択コード
    COMBOKEY_MS_KOUMOKU_PARTITION_CODES: 'public.ms_koumoku_partition_codes',     //ローカルストレージ用コード一覧

    ZYOKEN_CODE: 'public.zyoken_code',
    ORDER_CODE: 'public.order_code',
    SERCH_STR: 'public.search_str',

    /** 建物表示 */
    TATEMONO_VIEW_FLAG: 'public.tatemono_view_flag',
    MS_MENU_VIEW_FLAG: 'public.ms_menu_view_flag',

    DATA_SIZE: 'public.data_size',
    COMBOKEY_DATA_SIZES: 'public.data_sizes',

    SELECT_KOUZI: 'public.select_kouzi',

    LOGIN_USER: 'public.login_user',

    LOGIN_FLAG: 'public.login_flag',

    /** メニュー関連 */
    MENU_CATEGORY_CODE: 'menu.category_code',

}

