
import App from "../App";
import { HomeTop } from "../home/pages/HomeTop";

//import { Page404 } from "../common/pages/Page404";



export const HomeRoutes = [


    //    { path: "/error", chidlen: <Page404 /> },

    { path: "/top", chidlen: <HomeTop /> },

    //メニューページ
    {/** path: "/", chidlen: <IrmMainMenu />  */ },
    // { path: "/", chidlen: <IrmMainMenu /> },

    { path: "/", chidlen: <HomeTop /> },


];