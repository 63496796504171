import React, { FC } from 'react';
import image01 from '../../images/image01.png';
import court from '../../images/court.png';
import club_house from '../../images/club_house.png';
import sportopia from '../../images/sportopia.png';
import qrcode from '../../images/qrcode.png'

import { memo } from 'react';

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  HStack,
  VStack,
  Box,
  Link,
  Divider,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Hide,
} from '@chakra-ui/react'
//import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'
import { ReactElement } from 'react'
import imge01 from '../images/image01.png';
import { start } from 'repl';

export const HomeTop: FC = memo(() => {

  return (
    <VStack align={'start'} >
      <Container maxW={'5xl'} py={15} bg={"gray.100"}>
        <VStack>
          <Text
            textTransform={'uppercase'}
            color={'black'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
            as='u' >
            <Link href='https://www.soka-sport.org' isExternal color={'grey'} pl={'10px'}>
              公益法人草加市スポーツ協会会長推薦
            </Link>
          </Text>
          <Heading color={'blue'}>ようこそ テニスサークル「土曜会」へ</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>

            <Stack spacing={4} textAlign={"start"}>
              <Flex>
                <img src={image01} />
              </Flex>
            </Stack>

            <VStack textAlign={'left'}>

              <Text color={'black'} fontSize={'lg'} align={'start'} fontWeight={'bold'} >
                私たち「土曜会」は、
                <Text color={'blue'} >「元気で。笑って。健康で。」をモットーに</Text>
                谷塚スポートピアにて
                毎週土曜日に活動している<br />
                テニスサークルです。
              </Text>

              <Text color={'black'} fontSize={'lg'} align={'start'} textColor={'#ff6347'}  >
                「 参加希望の方、大歓迎！ 」
                <Text color={'black'}>（最寄駅：谷塚駅、竹ノ塚駅）
                  <Link href='https://www.google.co.jp/maps/place/%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%83%94%E3%82%A2/@35.8096624,139.7839453,16z/data=!4m15!1m8!3m7!1s0x60189176c778a4ff:0x3869306784dfecda!2z44CSMzQwLTAwMjQg5Z-8546J55yM6I2J5Yqg5biC6LC35aGa5LiK55S677yR77yT77yY!3b1!8m2!3d35.8083766!4d139.7868914!16s%2Fg%2F11clmxx2ph!3m5!1s0x60189176c77e7cd3:0xdf4b54cfdcda3362!8m2!3d35.808302!4d139.786982!16s%2Fg%2F1tf6gn1r?hl=ja&entry=ttu' isExternal
                    fontSize={'medium'}
                    color={'gray'}  >
                    マップ
                  </Link>
                </Text>
              </Text>

              <Text mt={'10px'} color={'grey'} ml={'-90'}>
                <Link href='http://www.sportopia.co.jp/t-top.htm' isExternal color={'grey'} pl={'10px'}>
                  スポートピア（テニスコート＆ゴルフ練習場）
                </Link>

              </Text>
              {/* <Stack
              spacing={4}
              width={5}
              divider={
                <StackDivider borderColor={'red'} />
              }>

            </Stack> */}
            </VStack>
          </SimpleGrid>

        </VStack >
      </Container >
      {/* ++++++++++++ 連絡先 +++++++++++++ */}
      < Container maxW={'5xl'} py={15} bg={"gray.100"} >
        <VStack>
          <Text fontSize={'lg'} align={'start'} color={'black'}>
            <Link href="tel:0489256684" color={'blue'} isExternal>連絡先:０４８－９２５－６６８４（ 鈴木 ）　</Link>
            ※気軽にご連絡ください。お待ちしています。
          </Text>
          <Divider orientation='horizontal' h={'2px'} bg={'gray'} />
          <Box textAlign={'start'} color={'black'}>
            <table>
              <tr><td>住所</td><td><br /></td></tr>
              <tr><td width={'50px'}><br /></td>
                <td >
                  <Link href='https://www.google.co.jp/maps/place/%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%83%94%E3%82%A2/@35.8096624,139.7839453,16z/data=!4m15!1m8!3m7!1s0x60189176c778a4ff:0x3869306784dfecda!2z44CSMzQwLTAwMjQg5Z-8546J55yM6I2J5Yqg5biC6LC35aGa5LiK55S677yR77yT77yY!3b1!8m2!3d35.8083766!4d139.7868914!16s%2Fg%2F11clmxx2ph!3m5!1s0x60189176c77e7cd3:0xdf4b54cfdcda3362!8m2!3d35.808302!4d139.786982!16s%2Fg%2F1tf6gn1r?hl=ja&entry=ttu' isExternal color={'blue'} >
                    〒340-0024　埼玉県草加市谷塚上町138
                  </Link>
                </td></tr>
              <tr><td>日時</td><td><br /></td></tr>
              <tr><td><br /></td><td>毎週土曜日 10：00 ～ 13：00（3時間）</td></tr>

              <tr><td>施設</td><td><br /></td></tr>
              <tr><td><br /></td><td>オムニコ－ト（４面のうち、２面使用）</td></tr>
              <tr><td><br /></td><td>テニス専用駐車場（無料）</td></tr>
              <tr><td><br /></td><td>壁打ち練習ボード</td></tr>
              <tr><td><br /></td><td>クラブハウス</td></tr>
              <tr><td><br /></td><td>男女更衣室、シャワー</td></tr>
              <tr><td><br /></td><td>ロビー</td></tr>

              <tr><td>資格</td><td><br /></td></tr>
              <tr><td><br /></td><td>①テニスの初心者の方から中級の方まで制限はありません。</td></tr>
              <tr><td><br /></td><td>②現在の在籍年齢50代～となっています。が、性別、年齢は問いません。</td></tr>
              <tr><td><br /></td><td>③責任が持てて楽しめる方</td></tr>

              <tr><td>費用</td><td><br /></td></tr>
              <tr><td><br /></td><td>参加時のみ2000円 ※ボール代（時折）</td></tr>
            </table>

            <Divider orientation='horizontal' h={'2px'} bg={'blue'} />
            <Box pt={'10px'}>施設照会</Box>
            <Box>
              <img src={court} width={'800px'} />
            </Box>
            <Box>
              <img src={club_house} width={'800px'} />
            </Box>
            <Box>
              <img src={sportopia} width={'800px'} />
            </Box>
            <Divider orientation='horizontal' h={'2px'} bg={'blue'} />

            <Box>
              <img src={qrcode} width={'100px'} />
              © 2023-Saturday Tennis Circle
            </Box>
            <Box color={'gray.100'}>
              <h3>足立区 テニスサークル 草加市 谷塚 竹ノ塚 テニスコート メンバー 募集 </h3>
            </Box>
          </Box>

        </VStack>
      </Container >
    </VStack >
  );

}
);