import React from 'react';

import './App.css';
import { Router } from './router/Router';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider, } from '@chakra-ui/react';
import { Conf } from './conf/Conf';
import { theme } from "./Theme";

function App() {
  return (

    <ChakraProvider theme={theme}>
      <BrowserRouter basename={Conf.URL_SUB}>

        <div className="App">
          <Router />
        </div>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
